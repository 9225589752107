import { Injectable } from '@angular/core'

/**
 * ThemeService is a service that handles the theme of the application.
 */
@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  /**
   * The style element
   */
  private readonly style: HTMLLinkElement

  /**
   * Constructor
   */
  constructor() {
    this.style = document.createElement('link')
    this.style.rel = 'stylesheet'
    document.head.appendChild(this.style)
    this.init()
  }

  /**
   * Initializes the theme
   */
  init() {
    const theme = this.current
    if (theme !== undefined) {
      this.style.href = `/${theme}.css`
    }
    document.body.classList.remove('light', 'dark')
    document.body.classList.add(theme)
  }

  /**
   * Gets the current theme
   * @returns the current theme
   */
  public get current(): string {
    return localStorage.getItem('theme') ?? 'light'
  }

  /**
   * Sets the current theme
   * @param value the current theme
   */
  public set current(value: string) {
    localStorage.setItem('theme', value)
    this.style.href = `/${value}.css`
    document.body.classList.remove('light', 'dark')
    document.body.classList.add(value)
  }
}
