import { Injectable } from '@angular/core'
import { TranslocoService, getBrowserLang } from '@jsverse/transloco'

import { PrimeNGConfig } from 'primeng/api'

import { AuthResponse } from '@goplanner/module-builder'

/**
 * Translate service
 */
@Injectable({
  providedIn: 'root'
})
export class TranslateService {
  /**
   * Translate service constructor
   * @param translocoService Transloco Service
   * @param primengConfig PrimeNG Config
   * On init, set the default language to the browser language
   */
  constructor(
    private translocoService: TranslocoService,
    private primengConfig: PrimeNGConfig
  ) {}

  /**
   * Init the service on app start
   */
  init() {
    // Try to get the language from the auth object
    const auth = localStorage.getItem('auth')
    if (auth) {
      const authObj = JSON.parse(auth) as AuthResponse
      const lang = authObj.id_ultima_idioma
      if (lang && Object.keys(this.langKeys).includes(lang.toString())) {
        this.translocoService.setDefaultLang(
          this.langKeys[lang as keyof typeof this.langKeys]
        )
        this.setLanguage(this.langKeys[lang as keyof typeof this.langKeys])
        return
      }
    }

    // Try to get the language from the browser
    const lang = getBrowserLang()
    if (lang) {
      this.translocoService.setDefaultLang(lang)
      this.setLanguage(lang)
    }
  }

  /**
   * Language keys
   */
  langKeys = {
    1: 'es',
    4: 'en',
    3: 'de',
    2: 'fr',
    6: 'ro',
    14: 'pt'
  }

  /**
   * Set the language to the given key
   * @param key Language key
   */
  setLanguage(key: string) {
    document.documentElement.lang = key
    this.translocoService.setActiveLang(key)
    this.translocoService.selectTranslateObject('primeng').subscribe((res) => {
      this.primengConfig.setTranslation(res)
    })
  }

  /**
   * Get the current language
   * @returns Current language
   */
  getLanguage() {
    return this.translocoService.getActiveLang()
  }

  getFlag(language: string) {
    switch (language) {
      case 'en':
        return 'gb'
      default:
        return language
    }
  }

  getKey(language: string) {
    switch (language) {
      case 'en':
        return 4
      case 'fr':
        return 2
      case 'es':
        return 1
      case 'de':
        return 3
      case 'ro':
        return 6
      case 'pt':
        return 14
      default:
        return 1
    }
  }

  /**
   * Get the language change observable
   * @returns Language change observable
   */
  onChangeLanguage() {
    return this.translocoService.langChanges$
  }
}
